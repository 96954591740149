<template>
  <div id="users-list-list-view" class="users-list-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Users'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>
          <div class="p-3"></div>
          <vs-button @click="openAddUserPage" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddUser') }}
          </vs-button>
          <vs-button @click="openStaffHistoryPage"
                     class="ml-2"
                     v-if="activeUserInfo.internal_role == 0"
                     type="filled" >{{ $t('StaffHistory') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

  <!-- Error List State-->
  <transition name="fade">
    <div class="h-screen flex w-full bg-img" v-if="errorFetching">
      <div
        class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
             class="mx-auto mb-4 max-w-full">
        <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div class="vx-row">
      <div class="w-full">
        <!-- Empty List State -->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="users.length === 0 && !errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                   class="mx-auto mb-4 max-w-full">-->
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
            </div>
          </div>
        </transition>
        <vs-table-modified v-show="users.length > 0 && !errorFetching" ref="table"
                  :max-items="itemsPerPage" search
                  :data="users">

          <template slot="thead">
            <vs-th >{{$t('UserId')}}</vs-th>
            <vs-th >{{$t('UserName')}}</vs-th>
            <vs-th >{{$t('AccountStatus')}}</vs-th>
            <vs-th>{{ $t('Action') }}</vs-th>
          </template>


          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <p class="user-name font-medium truncate">{{ tr.user_id }}</p>
              </vs-td>

              <vs-td>
                <vs-col>
                  <p class="user-name font-medium truncate">{{ tr.user_name }}</p>
                  <p class="vs-list--subtitle text-gray">{{ tr.is_manager == 0 ? $t('Staff').toLowerCase() : $t('Manager').toLowerCase() }}</p>
                </vs-col>
              </vs-td>

              <vs-td>
                <div class="text-center flex justify-center">
                  <vs-chip :color="getStatusColor(tr.is_active)" class="user-status">{{
                      getStatusText(tr.is_active) | title }}
                  </vs-chip>
                </div>
              </vs-td>


              <vs-td class="whitespace-no-wrap vx-row flex justify-end">
                <vs-button class=""
                           color="success"
                           v-if="tr.is_manager == 1"
                           type="border" @click="openUserAssign(tr.user_id)">{{ $t('Assign') }}</vs-button>
                <vs-button class="ml-2" @click="openUserEditPage(tr.user_id)">{{ $t('Edit') }}</vs-button>
                <!--              <div class="px-2"></div>-->
                <vs-button class="ml-2" :color="getStatusColor(tr.status)"
                           @click="inActivateUser(tr.user_id, tr.internal_permission_id, !tr.is_active)" >{{tr.is_active === true ? $t('Deactivate') : $t('Activate') }}</vs-button>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>

          <template slot="footer">
          </template>

          <template slot="footer-left">
            <div class="flex">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>

export default {
  name: 'ViewUsers',
  data() {
    return {
      itemsPerPage: 20,
      isMounted: false,
      errorFetching: false,
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      searchText: '',
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Users', i18n: 'Users', active: true },
      ],
      currentPage: 1,
    }
  },
  computed: {
    vPage() {
      return this.$store.state.user.vPage
    },
    vTotalPages() {
      return this.$store.state.user.vTotalPages
    },
    vTotalCount() {
      return this.$store.state.user.vTotalCount
    },
    users() {
      if (this.searchText.length > 0) {
        return this.$store.state.user.users.filter((c) => c.user_name.toLowerCase().startsWith(this.searchText.toLowerCase()))
      }
      return this.$store.state.user.users
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.users.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    inActivateUser(userId, internalPermissionId, activate) {
      const payload = {
        is_active: activate === true ? 1 : 0,
        internal_permission_id: internalPermissionId,
      }
      payload.user_id = userId
      this.$vs.loading()

      this.$store.dispatch('user/updateUser', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.loadUsers()
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    openUserAssign(item) {
      this.$router.push({ name: 'user-assign', params: { userId: item } })
    },
    openUserEditPage(item) {
      this.$router.push({ name: 'user-edit', params: { userId: item } })
    },
    openAddUserPage() {
      this.$router.push({ name: 'user-add' })
    },
    openStaffHistoryPage() {
      this.$router.push({ name: 'user-assign-history' })
    },
    getStatusText(status) {
      if (status === true) return 'Active'
      return 'InActive'
    },
    getStatusColor(status) {
      if (status === true) return 'success'
      return 'warning'
    },
    listPageChanged(item) {
      this.loadUsers(this.currentPage - 1)
    },
    loadUsers(page = '0') {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', { page, dontSendManagerFlag: true })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.loadUsers()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
  #users-list-list-view {
    .user-image {
      /*max-width: 200px;*/
      max-height: 40px;
      object-fit: cover;
    }

    .vs-con-table {

      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .users-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .user-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
